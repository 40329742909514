
import { defineComponent, ref } from "vue";

export default defineComponent({
    name: 'product-material',
    setup() {

        return {}
    }
})
